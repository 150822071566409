import React from "react"
import Equipements from "assets/equipements.svg";
import Maisons from "assets/maisons.svg";
import Patrimoine from "assets/patrimoine.svg";
import Tertiaire from "assets/tertiaire.svg";


const icons = {
  "equipements": Equipements,
  "maisons": Maisons,
  "patrimoine": Patrimoine,
  "tertiaire": Tertiaire,
};

const titles = {
  "equipements": "Equipements",
  "maisons": "Maisons",
  "patrimoine": "Patrimoine",
  "tertiaire": "Tertiaire",
};

const iconStyles = {
  fill:"#a63e3d",
  width: "3em",
  background: "white",
}

const containerStyles = {
  // padding: "1em 0.5em",
  // border: "1px solid #a63e3d",
  borderRadius: "1em",
  fontStyle: "italic"
};

const CategorieIcon = ({categorie, className, full}) => {
  const Icon = icons[categorie];
  return (
    <>
    {full && (
      <div className="categorie-icon-container" style={containerStyles}>
      
      <Icon className={className} style={{...iconStyles}} title={titles[categorie]}/>
      <span style={{verticalAlign: "bottom"}}>&nbsp;{titles[categorie]}</span>
      </div>
    )}
    {!full && (
      <Icon className={className} style={iconStyles} title={titles[categorie]}/>
    )}
    </>
  )
}

export default CategorieIcon;