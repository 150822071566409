import React from "react"
import { Card } from "react-bootstrap"
import {Link } from "gatsby"
import Img from "gatsby-image"
import DefaultImage from "assets/default.svg"
import CategorieIcon from "../CategorieIcon"

const cardStyles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "flex-start",
  // padding: "1rem",
  marginBottom: "1rem",
  // boxShadow: "5px 5px 25px 0 rgba(46,61,73,.2)",
  backgroundColor: "#fff",
  borderRadius: "1em",
  // maxWidth: "300px",
}

const CardItem = ({item}) => {
  return (
      <Card style={cardStyles}>
        {item.url && (<Link className="card-img" to={item.url} activeClassName="active">
          {item.fluid && (<Img
            className="card-img card-image-top"
            fluid={item.fluid}
          />)}
          {item.fixed && (<Img
            className="card-img card-image-top"
            fixed={item.fixed}
          />)}
          {!item.fluid && !item.fixed && (
            <DefaultImage className="default-img card-img card-image-top"/>
          )}
          {item.categorie && (
            <CategorieIcon categorie={item.categorie} className="categorie-icon"/>
          )}
          <Card.Title>
          {item.title}
          </Card.Title>
        </Link>)}
    </Card>
  );
}

export default CardItem;